<template>
    <onboarding-layout>
        <template slot="title"> {{ $t('general.verify_emailadres') }} </template>
        <template slot="lead-text"> {{ message }} </template>
        <router-link :to="{ name: 'login' }">{{ $t('general.login') }}</router-link>
        <loader v-if="loading" />
    </onboarding-layout>
</template>

<script>
import Loader from '@/components/Loader';
import { analyticsTrack } from '@/services/analytics';
import { mapActions } from 'vuex';
import OnboardingLayout from '../layouts/OnboardingLayout.vue';

export default {
    components: {
        Loader,
        OnboardingLayout
    },

    data() {
        return {
            message: '',
            loading: false
        };
    },
    async created() {
        this.loading = true;
        if (!this.$route.query?.token) {
            // return this.$router.push({ name: 'login' });
        }

        try {
            await this.handleVerifyEmailadres(this.$route.query.token);
            this.message = this.$t('general.verification_success');

            analyticsTrack('Verified Email Address');
        } catch (e) {
            this.message = this.$t('errors.verificationFailed');
        } finally {
            this.loading = false;
        }
    },

    methods: {
        ...mapActions('auth', ['verifyEmailadres']),
        async handleVerifyEmailadres(token) {
            return this.verifyEmailadres({ token });
        }
    }
};
</script>

<style lang="scss" scoped>
.img_col {
    border-top-left-radius: $border-radius-root;
    border-bottom-left-radius: $border-radius-root;
    background-color: rgba(232, 229, 241, 0.48);

    .img_wrapper {
        width: 40%;
        padding: 10% 0;
    }
}
</style>
